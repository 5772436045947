// KitchenContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { apiGetKitchenList, apiSetKitchenStation } from "apis/Kitchen";

const KitchenContext = createContext();

export const useKitchen = () => {
  return useContext(KitchenContext);
};

export const KitchenProvider = ({ children }) => {
  const [kitchenStations, setKitchenStations] = useState([]);
  const [kitchenListSelected, setKitchenListSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const isLogin = localStorage.getItem("kitchenStations");
  useEffect(() => {
    if (isLogin) {
      getKitchenList();
    }
  }, [isLogin]);

  const getKitchenSelected = async () => {
    const temp = localStorage.getItem("kitchenStations");
    if (temp) {
      const parsedTemp = JSON.parse(temp);
      setKitchenListSelected(parsedTemp);
    }
  };

  const getKitchenList = async () => {
    try {
      setLoading(true);
      const res = await apiGetKitchenList();
      setKitchenStations(res?.data?.getKitchenStations ?? []);
      await getKitchenSelected();
    } catch (err) {
      console.error("Error getting kitchen stations:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (obj) => {
    let updatedList = [...kitchenListSelected];
    const foundItem = updatedList.find((item) => item?.id == obj?.id);

    if (foundItem) {
      console.log(
        "foundItem: ",
        updatedList.filter((item) => item?.id != obj?.id)
      );
      updatedList = updatedList.filter((item) => item?.id != obj?.id);
    } else {
      updatedList.push(obj);
    }

    setKitchenListSelected(updatedList);
    localStorage.setItem("kitchenStations", JSON.stringify(updatedList));

    apiSetKitchenStation({
      kitchen_stations_ids: updatedList.map((item) => parseInt(item?.id)),
    });
  };

  return (
    <KitchenContext.Provider
      value={{
        kitchenStations,
        kitchenListSelected,
        loading,
        handleChange,
        getKitchenList,
        getKitchenSelected,
        setKitchenListSelected,
      }}
    >
      {children}
    </KitchenContext.Provider>
  );
};
