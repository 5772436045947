import React from "react";

export default function IconNoti() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="30"
      viewBox="0 0 26 30"
      fill="none"
    >
      <path
        d="M16.1663 24.9166C17.3917 24.9166 18.0391 26.3673 17.2217 27.2796C16.6907 27.8739 16.0401 28.3492 15.3125 28.6744C14.585 28.9996 13.7969 29.1673 13 29.1666C12.2031 29.1673 11.415 28.9996 10.6875 28.6744C9.95995 28.3492 9.30935 27.8739 8.77834 27.2796C7.99634 26.407 8.55451 25.0427 9.67651 24.9266L9.83234 24.9181L16.1663 24.9166ZM13 0.833313C14.9238 0.833313 16.5502 2.11256 17.0729 3.8664L17.1381 4.10865L17.1494 4.16956C18.7111 5.05059 20.0419 6.28904 21.0328 7.78348C22.0237 9.27792 22.6464 10.9858 22.8501 12.7673L22.8898 13.1739L22.9167 13.5833V17.7356L22.9464 17.9282C23.1404 18.9721 23.718 19.9055 24.5657 20.5448L24.8023 20.7106L25.0318 20.8508C26.2501 21.5407 25.8251 23.3526 24.4977 23.4915L24.3333 23.5H1.66667C0.21034 23.5 -0.298243 21.5676 0.968257 20.8508C1.50805 20.5453 1.97444 20.1254 2.33469 19.6205C2.69494 19.1156 2.94031 18.538 3.05359 17.9282L3.08334 17.7256L3.08476 13.5181C3.17113 11.6678 3.70973 9.86684 4.65341 8.27289C5.59709 6.67894 6.91708 5.34059 8.49784 4.37498L8.84917 4.16815L8.86334 4.10723C9.06373 3.2601 9.51918 2.49483 10.1682 1.91471C10.8172 1.33458 11.6286 0.96752 12.4928 0.863063L12.7507 0.83898L13 0.833313Z"
        fill="#333333"
      />
    </svg>
  );
}
