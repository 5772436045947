import { Popover, Row } from "antd";
import FilterIcon from "assets/icons/filterIcon";
import React, { useEffect, useState } from "react";
import CheckBoxOption from "./CheckBoxOption";
import { useKitchen } from "layouts/useFilterKitchen";

export default function FilterKitchen() {
  const {
    kitchenStations,
    kitchenListSelected,
    handleChange,
    getKitchenList,
    getKitchenSelected,
  } = useKitchen();
  useEffect(() => {
    getKitchenList();
    getKitchenSelected();
  }, []);
  const content = (kitchenStations, kitchenListSelected) => (
    <div style={{ padding: 16, maxHeight: 500, overflowY: "scroll" }}>
      {kitchenStations?.map((obj) => (
        <Row
          style={{
            alignItems: "center",
            gap: 12,
          }}
          key={obj?.id + Math.random()}
          value={obj?.id}
        >
          <CheckBoxOption
            isChecked={kitchenListSelected.find((item) => item?.id == obj?.id)}
            name={obj?.name}
            onChange={() => {
              handleChange(obj);
            }}
          />
        </Row>
      ))}
    </div>
  );
  return (
    <Popover
      content={content(kitchenStations, kitchenListSelected)}
      title=""
      trigger="click"
    >
      <div
        style={{
          height: 50,
          width: 50,
          background: "#E1E6EF",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 6,
          cursor: "pointer",
        }}
      >
        <FilterIcon />
      </div>
    </Popover>
  );
}
