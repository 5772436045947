import { Badge, Popover, Row, Col, Select, Switch, Drawer } from "antd";

import moment from "moment";
import React, { useState, useEffect } from "react";
import iconNoti from "../../assets/noti.png";
import blackNoti from "../../assets/black-noti.png";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spin } from "antd";
import { apiGetNotificationList } from "../../apis/Notification";
import DrawerMenu from "../../components/DrawerMenu";

import "./index.scss";
import IconNoti from "assets/icons/IconNoti";
import FilterIcon from "assets/icons/filterIcon";
import FilterKitchen from "components/FilterKitchen";
const urlMerchant = process.env.REACT_APP_MERCHANT_URL;
function Header(props) {
  const { reload } = props;
  const [listNotifications, setListNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadMore, setLoadMore] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [totalUnread, setTotalUnread] = useState(0);

  const getListNotifications = () => {
    apiGetNotificationList({ type: "merchant" }).then((res) => {
      if (res.data && !res.errors) {
        setListNotifications(res?.data?.getMerchantNotificationList?.items);
        setTotalPages(
          res?.data?.getMerchantNotificationList?.page_info?.total_pages
        );
        // setTotalUnread(res?.data?.getMerchantNotificationList?.total_unread);
      }
    });
  };
  useEffect(() => {
    getListNotifications();
  }, [reload]);

  const calTime = (created_date) => {
    return moment(created_date).format("h:mm A, MM/DD/YYYY");
  };

  const styleNotificationItem = (is_read) => {
    return {
      height: "100px",
      padding: "20px",
      display: "flex",
      cursor: "pointer",
      borderBottom: "1px solid #384052",
      alignItems: "center",
    };
  };

  const iconStyle = (is_read) => {
    return {
      height: "48px",
      width: "48px",
      borderRadius: "300px",
      backgroundColor: "#F8CC3E",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      marginRight: 20,
    };
  };

  const getMoreNoties = (page) => {
    apiGetNotificationList({
      type: "merchant",
      currentPage: page,
    }).then((res) => {
      if (res.data && !res.errors) {
        const moreNoties = res?.data?.getMerchantNotificationList?.items || [];
        setListNotifications(listNotifications.concat(moreNoties));
        setTotalPages(
          res?.data?.getMerchantNotificationList?.page_info?.total_pages
        );
        // setTotalUnread(res?.data?.notifications?.total_unread);
      }
      setLoadMore(false);
    });
  };

  const loadMore = () => {
    if (currentPage < totalPages && !isLoadMore) {
      setLoadMore(true);
      setCurrentPage(currentPage + 1);
      getMoreNoties(currentPage + 1);
    }
  };

  const noti = () => (
    <InfiniteScroll
      dataLength={listNotifications?.length}
      next={loadMore}
      hasMore={true}
      height={500}
      loader={
        isLoadMore ? (
          <div style={{ textAlign: "center", marginTop: 20 }}>
            <Spin size="small" style={{ color: "pink" }} />
          </div>
        ) : (
          <></>
        )
      }
    >
      {listNotifications?.map?.((item, idx) => (
        <div
          key={idx}
          style={styleNotificationItem(item.is_read)}
          onClick={() => {}}
        >
          <div style={iconStyle(item.is_read)}>
            <img
              style={{ cursor: "pointer", width: "30px", height: "30px" }}
              src={blackNoti}
              alt="blackNoti"
              className="blackNoti"
            ></img>
          </div>
          <div className="content-right" style={{ width: 330 }}>
            <span
              style={{
                display: "inline-block",
                whiteSpace: "break-spaces",
                overflow: "visible",
                color: "#384052 ",
              }}
            >
              {item.content}
            </span>
            <p style={{ color: "#384052 " }}>{calTime(item.created_date)}</p>
          </div>
        </div>
      ))}
    </InfiniteScroll>
  );

  const onOpenChange = (newStatus) => {
    if (newStatus) {
      getListNotifications();
    }
  };

  const title = (
    <Row>
      <Col flex="auto">
        <div className="notification-title">NOTIFICATIONS</div>
      </Col>
    </Row>
  );

  const isMerchant = localStorage.getItem("merchantAccount");
  return (
    <>
      {isMerchant && (
        <div style={{ display: "flex", marginRight: 16, alignItems: "center" }}>
          <div>Merchant View</div>
          <Switch
            onChange={() => {
              const url = `${urlMerchant}`;
              window.location.href = url;
            }}
            style={{ marginLeft: 5 }}
          />
        </div>
      )}
      <div style={{ display: "flex", alignItems: "center", gap: 24 }}>
        <FilterKitchen />
        <Popover
          title={title}
          content={noti}
          placement="bottomRight"
          className="dropdown-noti"
          trigger="click"
          onOpenChange={onOpenChange}
        >
          <Badge
            count={totalUnread ?? 0}
            style={{
              backgroundColor: "#E02D3C",
              color: "#fff",
              borderColor: "#E1E6EF",
              fontWeight: "bold",
              top: "16px",
              right: "14px",
            }}
          >
            <div
              style={{
                height: 50,
                width: 50,
                background: "#E1E6EF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 6,
                cursor: "pointer",
              }}
            >
              <IconNoti />
            </div>
          </Badge>
        </Popover>

        <DrawerMenu />
      </div>
    </>
  );
}

export default Header;
