import { Modal } from "antd";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "components/Button/CustomButton";
import icon_delivery from "../../assets/icon_delivery.svg";
import icon_cooking from "../../assets/icon_cooking.svg";
import moment from "moment";
import "./index.scss";
import { Colors } from "../../utils/colors";
import iconShop from "../../assets/restaurent.png";
import iconMoto from "../../assets/shipping.png";
import { ThemeContext } from "handlers/ThemeContext";
import ButtonChangeStatusItem from "components/Oders-V2/components/ButtonChangeStatusItem";

const ORDER_STATUS = {
  READY: "ready",
  COOKING: "cooking",
  SENT: "sent",
};

const ORDER_TYPE = {
  QUOTE: "QUOTE",
  ORDER: "ORDER",
};

export default function OrderDetail({
  isShowOrderDetail = false,
  onClose = () => {},
  data = null,
  handleSetCooking,
  handleSubmitCookingOrder,
  handleSubmitCompleteOrder,
  handleCancelItem,
  handleSetListItemAction,
  loading = false,
}) {
  const { t } = useTranslation();
  const [loadingCancel, setLoadingCancel] = React.useState(false);
  const order = data;
  let listItems = [];
  let cartId = data?.items[0]?.cart_id;
  let type = data?.items[0]?.type;
  data?.items?.map((item) => {
    listItems.push(item?.id);
  });
  const onCloseModal = () => {
    onClose();
  };
  const reportIssue = () => {};

  const cooking = () => {
    handleSetListItemAction(cartId, listItems, type, "cooking");
  };

  const readyToShip = () => {
    console.log(data);
    handleSetListItemAction(cartId, listItems, type, "ready");
  };

  const completeOrder = () => {
    data?.items?.map((item) => {
      handleSubmitCompleteOrder(item?.id, data?.type);
    });
  };

  const cancelItem = async () => {
    if (data?.type == ORDER_TYPE.QUOTE) {
      setLoadingCancel(true);
      // data?.items?.map((item) => {
      //   handleCancelItem(`${data?.type}-${item?.id}`);
      // });
      handleSetListItemAction(cartId, listItems, type, "cancel");
      setTimeout(() => {
        setLoadingCancel(false);
        onCloseModal();
      }, 1500);
    }
  };
  const { isLightMode } = useContext(ThemeContext);
  React.useEffect(() => {
    if (!loading) {
      onCloseModal();
    }
  }, [loading]);

  const renderBottom = () => {
    if (data?.status == ORDER_STATUS.READY) {
      return (
        // <CustomButton
        //   title={t("completeOrder")}
        //   onClick={completeOrder}
        //   width={"100%"}
        //   height={56}
        //   style={{
        //     color: "#fff",
        //     background: "#146C43",
        //     fontWeight: 600,
        //     fontSize: 18,
        //     marginTop: 40,
        //   }}
        //   isHover={false}
        //   renderIcon={() => (
        //     <img style={{ marginRight: 5 }} src={icon_delivery} />
        //   )}
        //   isLoading={loading}
        // />
        <></>
      );
    } else if (data?.status == ORDER_STATUS.COOKING) {
      return (
        <CustomButton
          title={t("Ready")}
          onClick={readyToShip}
          width={"100%"}
          height={56}
          style={{
            color: "#fff",
            background: "#146C43",
            fontWeight: 600,
            fontSize: 18,
            marginTop: 40,
          }}
          isHover={false}
          renderIcon={() => (
            <img style={{ marginRight: 5 }} src={icon_delivery} />
          )}
          isLoading={loading}
        />
      );
    }
    return (
      <div>
        <div className="flex-center" style={{ marginTop: 40 }}>
          <CustomButton
            title={t("Cook all")}
            onClick={cooking}
            width={"100%"}
            height={56}
            style={{
              color: "#000",
              background: "#FF9D00",
              fontWeight: 600,
              fontSize: 18,
            }}
            isHover={false}
            renderIcon={() => (
              <img style={{ marginRight: 5 }} src={icon_cooking} />
            )}
            isLoading={loading}
            disabled={loadingCancel}
          />
        </div>
        {data?.type == ORDER_TYPE.QUOTE && (
          <div className="flex-center" style={{ marginTop: 16 }}>
            <CustomButton
              title={t("cancel")}
              onClick={cancelItem}
              width={"100%"}
              height={56}
              style={{
                background: "transparent",
                fontWeight: 600,
                fontSize: 18,
                color: "#E31837",
              }}
              isHover={false}
              isLoading={loadingCancel}
              disabled={loading}
            />
          </div>
        )}
      </div>
    );
  };
  return (
    <Modal
      key="PopupOrderDetail"
      centered={true}
      destroyOnClose={true}
      className={`modal-cooking popup-orderDetail ${
        isLightMode ? "popup-orderDetail-light" : ""
      }`}
      title={null}
      visible={isShowOrderDetail}
      width={360}
      onCancel={onCloseModal}
      footer={null}
    >
      <div style={{ paddingTop: 30 }}>
        <div className="flex-center" style={{ marginBottom: 16 }}>
          <div
            style={{
              background:
                Colors[order?.cartID % Colors.length]?.backgroundColor,
              height: 32,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "5px",
              padding: "0 10px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              gap: 5,
            }}
          >
            {order?.type === "QUOTE" ? (
              <img
                src={iconShop}
                alt=""
                style={{ height: 24, width: 24 }}
              ></img>
            ) : (
              <img src={iconMoto} alt="" style={{ height: 24, width: 24 }} />
            )}
            <h3
              style={{
                color: Colors[order?.cartID % Colors.length]?.textColor,
              }}
            >
              {order?.order_number
                ? ` ${order?.order_number}`
                : ` ${order?.cartID}`}{" "}
              {order?.table_name ? `- ${order?.table_name}` : ""}
            </h3>
          </div>
          <div className={` ${isLightMode ? "text-black" : "text-white"}`}>
            {moment(order?.created_at).format("HH:mm A")}
          </div>
        </div>

        {order?.items.map((item, index) => (
          <div
            className={`${
              isLightMode ? "order-item-info-light" : "order-item-info"
            }`}
            key={`${item.id}-${index}`}
          >
            <div className="flex-sb-c">
              <span
                className={` order-item-product-name ${
                  isLightMode ? "text-black" : ""
                }`}
              >
                {item?.product_name}
              </span>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span
                  className={` ${isLightMode ? "text-black" : "text-white"}`}
                >
                  {item?.quantity ? `x${item?.quantity}` : "x1"}
                </span>
                <div style={{ width: 60 }} />
              </div>
            </div>
            {item?.note && (
              <div>
                <span
                  className={`order-item-product-note ${
                    isLightMode ? "note-light-mode" : ""
                  }`}
                >
                  • {item?.note}
                </span>
              </div>
            )}
          </div>
        ))}
        {renderBottom()}
      </div>
    </Modal>
  );
}
