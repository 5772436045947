import { RightCircleOutlined } from "@ant-design/icons";
import React, { useRef, useState, useEffect, useContext } from "react";
import "./index.scss";
import iconShop from "../../assets/restaurent.png";
import iconMoto from "../../assets/shipping.png";
import moment from "moment";
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import ButtonChangeStatusItem from "./components/ButtonChangeStatusItem";
import { ThemeContext } from "handlers/ThemeContext";
import { Colors } from "utils/colors";
import { getLastFourChars } from "utils/Validation";

const TYPE_QUICK_ORDER = "Counter";

function OrderV2(props) {
  const [countdownDate, setCountdownDate] = useState(new Date());
  const [isNotResponse, setNotResponse] = useState(false);

  const {
    order,
    openModal,
    handleSubmitBom,
    id,
    playSound = () => {},
    saveOrderListNotResponse = () => {},
    orderListNotResponse = [],
    playOrderNOtResponseAgain = () => {},
    turnOffAppSound,
    updateOrderNextStatus,
    itemStatus,
    onServed,
  } = props;
  const findOrderOffSound = orderListNotResponse.find(
    (obj) => obj?.id == order?.id
  );
  const isOffSound =
    findOrderOffSound && findOrderOffSound?.isOffSound ? true : false;
  const timePause =
    findOrderOffSound && findOrderOffSound?.timePause
      ? findOrderOffSound?.timePause
      : null;

  const showDetail = useRef(true);

  const getTypeTextOrder = () => {
    if (order?.table_name && order?.table_name !== TYPE_QUICK_ORDER)
      return "Dine in";
    if (order?.table_name && order?.table_name === TYPE_QUICK_ORDER)
      return "Take out";
    if (!order?.table_name && order?.order_number) return "Delivery";
    return "Unknow";
  };

  const getTextNameTable = () => {
    if (order?.table_name && order?.table_name === TYPE_QUICK_ORDER)
      return `QO - ${order?.cart_name}`;
    if (order?.table_name && order?.table_name !== TYPE_QUICK_ORDER)
      return `${order?.table_name} - ${order?.cart_name}`;
    if (!order?.table_name && order?.order_number)
      return `${getLastFourChars(order?.order_number)}`;
    return "Unknow";
  };

  const handleClickOrder = () => {
    // callApiGetTurnOffAppSound();
    if (showDetail.current) {
      openModal(itemStatus, order);
    } else {
      showDetail.current = true;
    }
  };

  useEffect(() => {
    let countDownInterVal = setInterval(
      () => setCountdownDate(new Date()),
      1000
    );
    return () => clearInterval(countDownInterVal);
  }, []);

  const timePauseOrder = timePause
    ? timePause + parseInt(turnOffAppSound) * 60000
    : 0;

  let distanceToTimePause =
    timePauseOrder - Date.parse(new Date(countdownDate));

  useEffect(() => {
    if (distanceToTimePause < 0 && isOffSound) {
      playOrderNOtResponseAgain({ order });
    }
  }, [distanceToTimePause]);

  const { t } = useTranslation();
  const { isLightMode } = useContext(ThemeContext);
  return (
    <Draggable
      draggableId={`${order?.cartID}+${order?.created_at}+${itemStatus}`}
      key={`${order?.cartID}+${order?.created_at}+${itemStatus}`}
      index={id}
    >
      {(provided, snapshot) => (
        <span
          key={id}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div
            className={`order-item
            ${isNotResponse && !isOffSound ? "notResponse" : ""} 
            ${isLightMode ? "order-item-light" : ""}
            `}
            onClick={() => {
              handleClickOrder();
            }}
          >
            <div
              style={{
                borderBottom: "1px solid rgba(237, 237, 237, 1)",
                paddingBottom: 12
              }}
            >
              <div
                style={{
                  border: "1.2px solid #1D2433",
                  width: "fit-content",
                  fontSize: 16,
                  padding: "12px 16px",
                  borderRadius: "99px",
                  marginTop: 16,
                  marginRight: 10,
                  marginLeft: "auto",
                }}
              >
                {getTypeTextOrder()}
              </div>
            </div>
            {order?.assign_from?.name && order?.status === "pending" && (
              <div className="order-item-moved">
                <RightCircleOutlined />
                {`${t("movedFrom")} ${order?.assign_from?.name}`}
              </div>
            )}
            <div
              className={`order-item-box ${
                isLightMode ? "order-item-box-light" : ""
              }`}
            >
              {order?.items.map((item, index) => (
                <div
                  className={`${
                    isLightMode ? "order-item-info-light" : "order-item-info"
                  }`}
                  key={`${item.id}-${index}`}
                >
                  <div className="flex-sb-c">
                    <div>
                      <span
                        className={` ${
                          isLightMode ? "text-black" : "text-white"
                        }`}
                        style={{
                          marginRight: 8,
                          fontWeight: 500,
                          fontSize: 16,
                        }}
                      >
                        {item?.quantity ? `${item?.quantity}x` : "1x"}
                      </span>
                      <span
                        className={` order-item-product-name ${
                          isLightMode ? "text-black" : ""
                        }`}
                        style={{
                          fontWeight: 500,
                          fontSize: 16,
                        }}
                      >
                        {item?.product_name}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ButtonChangeStatusItem
                        onClick={(e) => {
                          e?.stopPropagation();
                          updateOrderNextStatus(
                            `${item.id}`,
                            item.status === "sent" ? "cooking" : "ready",
                            item.type
                          );
                        }}
                        onServed={() => onServed(`${item.id}`, item.type)}
                        type={item.status}
                      />
                    </div>
                  </div>
                  {item?.note && (
                    <div>
                      <span
                        className={`order-item-product-note ${
                          isLightMode ? "note-light-mode" : ""
                        }`}
                      >
                        {item?.note}
                      </span>
                    </div>
                  )}
                </div>
              ))}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <h3
                  style={{
                    color: "#1D2433",
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                >
                  {getTextNameTable()}
                </h3>
                <div style={{ marginLeft: "auto" }}>
                  <div
                    className={` ${isLightMode ? "text-black" : "text-white"}`}
                  >
                    {moment(order?.created_at).format("HH:mm A")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </span>
      )}
    </Draggable>
  );
}

export default OrderV2;
