import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { MyRoutes } from "./routes";

import NetworkProvider from "./components/NetworkProvider";
import "./ReactotronConfig";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { ThemeProvider } from "handlers/ThemeContext";
import { KitchenProvider } from "layouts/useFilterKitchen";
const App = (props) => {
  return (
    <NetworkProvider>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <ThemeProvider>
            <KitchenProvider>
              <MyRoutes />
            </KitchenProvider>
          </ThemeProvider>
        </I18nextProvider>
      </BrowserRouter>
    </NetworkProvider>
  );
};

export default App;
