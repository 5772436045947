import { customNotification, setLocalStorage } from "utils/CommomFunction";
import { Modal } from "antd";
import Reactotron from "reactotron-react-js";
import axiosClient from "./client";
const END_POINT = `${process.env.REACT_APP_URL}/graphql`;

const { error } = Modal;

export * from "./callApi";

export const callApi = async (query, variables, isLogin = true) => {
  const accessToken = localStorage.getItem("access_token") || "";
  const store_view_code = localStorage.getItem("store_view_code");
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };
  if (store_view_code && store_view_code.length > 0) {
    headers = {
      ...headers,
      Store: store_view_code,
    };
  }

  if (!accessToken && isLogin) {
    localStorage.clear();
    window.location.href = `${window.location.origin}/login`;
  }

  try {
    const result = await axiosClient.post(
      END_POINT,
      { query, variables },
      { headers }
    );
    Reactotron.apisauce(result);
    if (result.data?.errors && result.data?.errors[0]?.message) {
      if (
        result.data?.errors[0]?.message &&
        result.data?.errors[0]?.extensions?.category == "graphql-authorization"
      ) {
        const popState = localStorage.getItem("popState") || "1";
        if (popState === "1") {
          setLocalStorage("popState", 0);
        }
        let timer = setTimeout(() => {
          localStorage.clear();
          window.location.reload();
        }, 3000);

        return error({
          title: "Session Expired",
          content: "Please log in again!",
          onOk() {
            localStorage.clear();
            window.location.reload();
            clearTimeout(timer);
          },
        });
        // default:
        //   notification('error',result.data?.errors[0]?.message )
      }
    }
    return result.data;
  } catch (e) {
    if (e?.message && e?.message?.includes("Network Error")) {
      customNotification("error", "Please check your internet connection.");
    }
    customNotification("error", "An error occurred during processing!");
  }
  return true;
};
